import React from 'react'

import { HBox } from 'src/ui/layout'
import { Hero } from 'src/ui/organisms'
import { Body } from 'src/ui/typography'

type Props = {
  images: TImages
}

export const Tech: React.FC<Props> = ({ images }) => (
  <Hero background={images['tech-mobile']} title="Технологизация отделов">
    <HBox />
    <Body>
      В отделах работает программа развития сотрудников и технологий. Каждый
      человек тратит до&nbsp;четырех часов в&nbsp;неделю
      на&nbsp;профессиональное развитие.
    </Body>
  </Hero>
)
