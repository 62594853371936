import React from 'react'

import { HBox } from 'src/ui/layout'
import { HeroDesktopSecondary } from 'src/ui/organisms'
import { Body } from 'src/ui/typography'

type Props = {
  images: TImages
}

export const Tech: React.FC<Props> = ({ images }) => (
  <HeroDesktopSecondary
    contentPosition="top-right"
    background={images['tech']}
    title="Технологизация отделов"
    titleSize="h2"
    titleAs="h2"
    height={655}
  >
    <HBox />
    <Body
      style={{
        maxWidth: '800px',
      }}
    >
      В отделах работает программа развития сотрудников и технологий. Каждый
      человек тратит до&nbsp;четырех часов в&nbsp;неделю
      на&nbsp;профессиональное развитие.
    </Body>
    <HBox height={24} />
    <Body
      style={{
        maxWidth: '800px',
      }}
    >
      Тимлид выступает в роли наставника и&nbsp;помогает команде
      концентрироваться на цели. Эта система приводит к&nbsp;хорошим
      результатам. Например, один из наших Android-стажёров стал CEO&nbsp;своего
      продукта{' '}
      <a href="https://kode.ru/projects/fabble" target="_blank">
        Fabble.io
      </a>
    </Body>
  </HeroDesktopSecondary>
)
