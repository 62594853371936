import React from 'react'

import { SwiperSlide } from 'swiper/react/swiper-react.js'

import { SwiperSlider } from 'src/ui/organisms'
import { Body, Header } from 'src/ui/typography'
import { HBox, Wrapper } from 'src/ui/layout'
import { ImageWithDescription } from 'src/ui/organisms'

type Props = {
  images: TImages
}

export const Conf = ({ images }: Props) => (
  <>
    <Wrapper>
      <Header size="h4">Конференции, митапы, командировки</Header>
      <HBox />
      <Body>
        Сотрудники KODE регулярно ездят на конференции, участвуют в&nbsp;митапах
        и&nbsp;других событиях IT-комьюнити. KODE часто выступает партнерами на
        крупных ивентах и&nbsp;всегда поддерживает инициативы обмена опытом
        и&nbsp;знаниями.
      </Body>
    </Wrapper>

    <HBox height={24} />
    <SwiperSlider>
      <SwiperSlide>
        <ImageWithDescription
          description="Директор проектного офиса Вадим&nbsp;Кузенков работает во время TeamLeadConf"
          height={218}
          fluid={images['conf']}
          objectPosition="top left"
        />
      </SwiperSlide>
      <SwiperSlide>
        <ImageWithDescription
          description="Фронтендер Влад Панов на конференции DevFest"
          height={218}
          fluid={images['conf2']}
          objectPosition="top center"
        />
      </SwiperSlide>
      <SwiperSlide>
        <ImageWithDescription
          description="Юля Мицкевич рассказывает о разработке разговорных продуктов"
          height={218}
          fluid={images['conf3']}
          objectPosition="center"
        />
      </SwiperSlide>
    </SwiperSlider>
  </>
)
