import React from 'react'
import { Link } from 'gatsby'

import { breakpoints } from 'src/ui/dimensions'
import { styled, useTheme } from 'src/ui/theme'
import { HBox, Wrapper } from 'src/ui/layout'
import { Image } from 'src/ui/atoms'
import { Header, Body } from 'src/ui/typography'

import VisibilitySensor from 'react-visibility-sensor'
import { useSpring, animated } from 'react-spring'

const Title = styled.div`
  opacity: 0;
  position: absolute;

  right: 64px;
  bottom: 64px;
  max-width: 488px;
  padding: 0 16px;
  z-index: 2;
  text-align: right;

  transition: opacity 0.3s;
  @media (max-width: ${breakpoints.tablet}px) {
    display: none;
  }
`

const Container = styled.div`
  padding: 64px 0;

  position: relative;
  background-color: ${({ theme }) => theme.colors.white.primary};

  transition: background-color 0.3s;
  &:hover {
    background-color: ${({ theme }) => theme.colors.brand.primary};

    ${Title} {
      opacity: 1;
    }
  }
`

const Bg = styled(Image)`
  position: absolute !important;
  z-index: 0;

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  mix-blend-mode: multiply;
`

const Content = styled(animated.div)`
  position: relative;
  padding: 40px 40px 40px;
  width: 43.9%;
  background: ${({ theme }) => theme.colors.white.primary};
  @media (max-width: ${breakpoints.tablet}px) {
    width: 100%;
  }
`

type Props = {
  images: TImages
}

export const Learn: React.FC<Props> = ({ images }) => {
  const theme = useTheme()

  const [visible, setVisible] = React.useState(false)

  const contentStyles = useSpring({
    opacity: visible ? 1 : 0,
    transform: visible ? 'translateX(0)' : 'translateX(-60px)',
  })

  const imageStyles = useSpring({
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    opacity: visible ? 1 : 0,
    transform: visible ? 'translateX(0)' : 'translateX(100px)',
    mixBlendMode: 'multiply',
  })

  const handler = (active: boolean) => {
    if (active) {
      setVisible(true)
    }
  }

  return (
    <VisibilitySensor onChange={handler} partialVisibility scrollDelay={2000}>
      <Container>
        <animated.div style={imageStyles}>
          <Bg fluid={images['learn']} />
        </animated.div>
        <Wrapper>
          <Content style={contentStyles}>
            <Header size="h2">Стажировки</Header>
            <HBox height={24} />
            <Body>
              С&nbsp;2016&nbsp;года KODE проводит стажировки
              по&nbsp;10&nbsp;направлениям, включая мобильную и веб-разработку,
              управление проектами, аналитику, QA-тестирование и&nbsp;дизайн.
            </Body>
            <HBox />
            <Body>
              Программы&nbsp;стажировок традиционно стартуют весной и осенью. В
              течение месяца мы&nbsp;учим основам разработки на
              кейсах&nbsp;KODE, проводим лекции и воркшопы, даём домашние
              задания и принимаем финальные проекты.
              <br />
              <br />
              Стажёры с&nbsp;лучшими результатами получают предложение
              присоединиться к&nbsp;команде. Благодаря стажировкам штат
              регулярно пополняется перспективными сотрудниками.
            </Body>
            <HBox />
            <Body weight="medium">
              <Link to="/training/">Больше о&nbsp;стажировках KODE&nbsp;→</Link>
            </Body>
            <HBox height={80} />
            <Header size="h2">Курсы</Header>
            <HBox height={24} />
            <Body>
              Вместе с&nbsp;калининградскими вузами ведём курсы
              для&nbsp;начинающих кодеров&nbsp;—&nbsp;обучаем разработке
              на&nbsp;iOS и&nbsp;Android. Лекторами выступают лучшие
              сотрудники&nbsp;KODE, которые имеют большой опыт решения реальных
              задач.
            </Body>
          </Content>
        </Wrapper>
        <Title>
          <Header size="h4" color={theme.colors.white.primary}>
            Матвей&nbsp;Правосудов читает лекцию в&nbsp;Университете
            на&nbsp;стажировке
          </Header>
        </Title>
      </Container>
    </VisibilitySensor>
  )
}
