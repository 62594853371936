import React from 'react'
import { Link } from 'gatsby'

import { HBox } from 'src/ui/layout'
import { Hero } from 'src/ui/organisms'
import { Body } from 'src/ui/typography'

type Props = {
  images: TImages
}

export const Learn: React.FC<Props> = ({ images }) => (
  <>
    <Hero background={images['learn']} title="Стажировки" textHeight={202}>
      <HBox />
      <Body>
        С&nbsp;2016&nbsp;года KODE проводит стажировки
        по&nbsp;10&nbsp;направлениям, включая мобильную и веб-разработку,
        управление проектами, аналитику, QA-тестирование и&nbsp;дизайн.
      </Body>
      <HBox />
      <Body weight="medium">
        <Link to="/training/">Больше о&nbsp;стажировках KODE&nbsp;→</Link>
      </Body>
    </Hero>
    <HBox height={54} />
    <Hero background={images['course']} title="Курсы" textHeight={202}>
      <HBox />
      <Body>
        Вместе с&nbsp;калининградскими вузами ведём курсы для&nbsp;начинающих
        кодеров&nbsp;—&nbsp;обучаем разработке на&nbsp;iOS и&nbsp;Android.
        Лекторами выступают лучшие сотрудники&nbsp;KODE, которые имеют большой
        опыт решения реальных задач.
      </Body>
    </Hero>
  </>
)
