import React from 'react'

import { HBox } from 'src/ui/layout'
import { HeroDesktopSecondary } from 'src/ui/organisms'
import { Body } from 'src/ui/typography'

type Props = {
  images: TImages
}

export const Inside: React.FC<Props> = ({ images }) => (
  <HeroDesktopSecondary
    contentPosition="bottom-left"
    background={images['inside']}
    title="KODE работает"
    adornment={true}
    titleSize="h2"
    titleAs="h1"
    objectPosition="bottom left"
  >
    <HBox height={24} />
    <Body
      style={{
        maxWidth: '640px',
      }}
    >
      В офисе мы занимаемся проектами, проводим митапы и&nbsp;мастер-классы.
      KODE&nbsp;—&nbsp;весомая часть жизни сотрудников
      и&nbsp;источник&nbsp;энергии для развития индивидуальных
      и&nbsp;командных&nbsp;инициатив.
    </Body>
  </HeroDesktopSecondary>
)
