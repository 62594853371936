import React from 'react'

import { breakpoints } from 'src/ui/dimensions'
import { styled } from 'src/ui/theme'
import { ImageWithDescription } from 'src/ui/organisms'
import { Body, Header } from 'src/ui/typography'
import { HBox, Col, Wrapper, VBox } from 'src/ui/layout'

const Title = styled.div`
  display: grid;

  grid-template-columns: 43.9% 52.3%;
  gap: 3.8%;
`

const Grid = styled.div`
  display: grid;

  grid-template-columns: 43.9% 24.2% 24.2%;
  gap: 3.8%;
  @media (max-width: ${breakpoints.tablet}px) {
    gap: 16px;
    grid-template-columns: 33% 33% 33%;
  }
`

type Props = {
  images: TImages
}

export const Conf: React.FC<Props> = ({ images }) => (
  <Wrapper>
    <Title>
      <Col>
        <Header size="h2" as="h2">
          Конференции, митапы, командировки
        </Header>
      </Col>

      <Col>
        <HBox height={8} />
        <Body>
          Сотрудники KODE регулярно ездят на конференции, участвуют
          в&nbsp;митапах и&nbsp;других событиях IT-комьюнити. KODE часто
          выступает партнерами на крупных ивентах и&nbsp;всегда поддерживает
          инициативы обмена опытом и&nbsp;знаниями.
        </Body>
        <HBox />
        <Body>
          Любое значимое событие&nbsp;—&nbsp;возможность получить новые знания
          и&nbsp;скиллы, поделиться своими наработками с сообществом. Это
          приветствуется и&nbsp;поддерживается:&nbsp;мы помогаем с доставкой на
          место, подготовкой к&nbsp;выступлению и&nbsp;ответами на сложные
          вопросы.
        </Body>
      </Col>
    </Title>

    <HBox height={64} />

    <Grid>
      <ImageWithDescription
        description="Директор проектного офиса Вадим&nbsp;Кузенков работает во&nbsp;время TeamLeadConf"
        height={300}
        fluid={images['conf']}
        objectPosition="top right"
      />
      <ImageWithDescription
        description="Фронтендер Влад Панов на конференции DevFest"
        height={300}
        fluid={images['conf2']}
        objectPosition="center"
      />
      <ImageWithDescription
        description="Юля Мицкевич рассказывает о разработке разговорных продуктов"
        height={300}
        fluid={images['conf3']}
        objectPosition="center"
      />
    </Grid>
  </Wrapper>
)
