import React from 'react'

import { breakpoints, useDimensions } from 'src/ui/dimensions'
import { styled, useTheme } from 'src/ui/theme'
import { HBox, Wrapper, Col } from 'src/ui/layout'
import { ImageWithDescription } from 'src/ui/organisms'
import { Body, Header } from 'src/ui/typography'
import { LINKS, STRONG_TEAM_TEXT } from './constants'

const Title = styled.div`
  display: grid;
  grid-template-columns: 43.9% 52.3%;
  gap: 3.8%;
`

const Grid = styled.div`
  display: grid;
  width: 100%;

  grid-template-columns: 43.9% 24.2% 24.2%;
  gap: 3.8%;

  @media (max-width: ${breakpoints.tablet}px) {
    gap: 16px;
    grid-template-columns: 33% 33% 33%;
  }
`

const Links = styled.div`
  background: ${({ theme }) => theme.colors.brand.primary};
  padding: 24px;
  height: 318px;
  box-sizing: border-box;
  a {
    color: ${({ theme }) => theme.colors.white.primary};
    text-decoration: none;
  }

  @media (max-width: ${breakpoints.tablet}px) {
    height: 328px;
  }
`

type Props = {
  images: TImages
}

export const Team: React.FC<Props> = ({ images }) => {
  const theme = useTheme()
  const { isTablet } = useDimensions()
  return (
    <Wrapper>
      <Title>
        <Col>
          <Header size="h2" as="h2">
            Сильная и&nbsp;уверенная команда
          </Header>
        </Col>

        <Col>
          <HBox height={8} />
          <Body>{STRONG_TEAM_TEXT}</Body>
        </Col>
      </Title>

      <HBox height={64} />

      <Grid>
        <ImageWithDescription
          description="Менеджеры готовятся выступать с&nbsp;экспертным материалом на&nbsp;конференции о&nbsp;заказной разработке"
          height={isTablet ? 328 : 318}
          fluid={images['team']}
          objectPosition="top left"
        />
        <ImageWithDescription
          description="Дизайнеры обсуждают новый концепт приложения"
          height={isTablet ? 328 : 318}
          fluid={images['team2']}
          objectPosition="top center"
        />
        <Links>
          <Header color={theme.colors.white.primary} size="h5">
            Мы пишем, о нас пишут:
          </Header>
          <HBox height={24} />
          {Object.values(LINKS).map((link) => (
            <>
              <Header color={theme.colors.white.primary} size="h5">
                <a target="_blank" href={link.link}>
                  {`${link.name} →`}
                </a>
              </Header>
              <HBox />
            </>
          ))}
        </Links>
      </Grid>
    </Wrapper>
  )
}
