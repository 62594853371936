import React from 'react'

import { HBox } from 'src/ui/layout'
import { Hero } from 'src/ui/organisms'
import { Body } from 'src/ui/typography'

type Props = {
  images: TImages
}

export const Inside: React.FC<Props> = ({ images }) => (
  <Hero
    background={images['inside-mobile']}
    title="KODE работает"
    adornment
    label="ВНУТРИ ОФИСА"
  >
    <HBox />
    <Body>
      В офисе мы занимаемся проектами, проводим митапы и&nbsp;мастер-классы.
      KODE&nbsp;—&nbsp;весомая часть жизни сотрудников
      и&nbsp;источник&nbsp;энергии для развития индивидуальных
      и&nbsp;командных&nbsp;инициатив.
    </Body>
  </Hero>
)
