import React from 'react'

type TSource = 'habr' | 'vc' | 'inc' | 'rbc' | 'dribble' | 'behance'

type TLink = {
  name: string
  link: string
}

export const LINKS: Record<TSource, TLink> = {
  habr: {
    name: 'Хабр',
    link: 'https://habr.com/ru/users/APPKODE/posts/',
  },
  vc: {
    name: 'vc.ru',
    link: 'https://vc.ru/u/552362-kode',
  },
  inc: {
    name: 'Inc',
    link:
      'https://incrussia.ru/fly/kode-popast-v-vysshuyu-ligu-razrabotchikov-prilozhenij-sobrav-luchshih-programmistov-v-mestnosti-smerti/',
  },
  rbc: {
    name: 'РБК',
    link: 'https://plus.rbc.ru/news/5d65178b7a8aa93df049ea5e',
  },
  dribble: {
    name: 'Dribble',
    link: 'https://dribbble.com/appkode',
  },
  behance: {
    name: 'Behance',
    link: 'https://www.behance.net/appkode',
  },
}

export const STRONG_TEAM_TEXT = (
  <>
    Наши разработчики используют современный стек, влияют на развитие проекта и
    выбор технологий. Команда всегда в&nbsp;курсе философии продукта и понимает,
    что&nbsp;решает важную задачу для пользователей.
    <br />
    <br />
    В KODE мы отталкиваемся от целей заказчика и наших возможностей, но тяготеем
    к гибкой&nbsp;методологии и&nbsp;небезызвестному фрэймворку&nbsp;Scrum.
    <br />
    <br />
    Команда&nbsp;KODE обладает широкой экспертизой в сфере разработки, дизайна,
    управления проектами и&nbsp;создания продуктов. Мы регулярно делимся опытом
    на ведущих российских и&nbsp;мировых площадках:{' '}
    <a target="_blank" href={LINKS.habr.link}>
      {LINKS.habr.name}
    </a>
    ,{' '}
    <a target="_blank" href={LINKS.vc.link}>
      {LINKS.vc.name}
    </a>
    ,{' '}
    <a target="_blank" href={LINKS.dribble.link}>
      {LINKS.dribble.name}
    </a>
    ,{' '}
    <a target="_blank" href={LINKS.behance.link}>
      {LINKS.behance.name}
    </a>
    . О&nbsp;нас&nbsp;писали{' '}
    <a target="_blank" href={LINKS.inc.link}>
      {LINKS.inc.name}
    </a>
    ,{' '}
    <a target="_blank" href={LINKS.rbc.link}>
      {LINKS.rbc.name}
    </a>
    .
  </>
)
