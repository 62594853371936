import React from 'react'

import { useDimensions } from 'src/ui/dimensions'
import { RootLayout } from 'src/layouts'
import { HBox } from 'src/ui/layout'
import { Conf, Inside, Learn, Team, Tech } from 'src/features/work'
import { Meta } from 'src/features/seo'

import { graphql } from 'gatsby'
import { Query } from 'hr/graphql-types'

type Props = {} & { data: Query }

const Work: React.FC<Props> = ({ data }) => {
  const images = data.allFile.edges.reduce<TImages>((acc, img) => {
    return {
      ...acc,
      [img.node.name]: img.node.childImageSharp?.fluid as TFluidObject,
    }
  }, {})

  const { isMobile } = useDimensions()
  return (
    <RootLayout>
      <Meta
        title="Профессиональная команда мобильной разработки — KODE"
        description="Работа в сильной и уверенной команде KODE: конференции, митапы, командировки ✔ Офис в Калининграде"
      />
      {isMobile ? <HBox /> : null}
      <Inside images={images} />
      {!isMobile ? <HBox height={138} /> : <HBox height={54} />}

      <Team images={images} />
      {!isMobile ? <HBox height={158} /> : <HBox height={54} />}
      <Tech images={images} />
      {!isMobile ? <HBox height={74} /> : <HBox height={54} />}
      <Learn images={images} />
      {!isMobile ? <HBox height={138} /> : <HBox height={54} />}
      <Conf images={images} />
      {!isMobile ? <HBox height={166} /> : <HBox height={54} />}
    </RootLayout>
  )
}

export const query = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/work//i" } }) {
      edges {
        node {
          name
          relativePath
          childImageSharp {
            fluid(maxWidth: 1900) {
              srcSet
              base64
              src
              aspectRatio
            }
          }
        }
      }
    }
  }
`

export default Work
