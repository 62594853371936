import React from 'react'

import { SwiperSlide } from 'swiper/react/swiper-react.js'

import { HBox, Wrapper } from 'src/ui/layout'
import { SwiperSlider } from 'src/ui/organisms'
import { Body, Header } from 'src/ui/typography'
import { ImageWithDescription } from 'src/ui/organisms'
import { LINKS, STRONG_TEAM_TEXT } from './constants'

type Props = {
  images: TImages
}

export const Team = ({ images }: Props) => (
  <Wrapper>
    <Header size="h4" as="h2">
      Сильная и уверенная команда
    </Header>
    <HBox />
    <Body>{STRONG_TEAM_TEXT}</Body>
    <HBox height={24} />
    <SwiperSlider>
      <SwiperSlide>
        <ImageWithDescription
          description="Менеджеры готовятся выступать с&nbsp;экспертным материалом на&nbsp;конференции о&nbsp;заказной разработке"
          height={218}
          fluid={images['team']}
          objectPosition="top left"
        />
      </SwiperSlide>
      <SwiperSlide>
        <ImageWithDescription
          description="Дизайнеры обсуждают новый концепт приложения"
          height={218}
          fluid={images['team2']}
          objectPosition="top center"
        />
      </SwiperSlide>
    </SwiperSlider>
  </Wrapper>
)
